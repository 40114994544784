import * as React from "react";
import * as ReactDOM from "react-dom";

import { App } from "./app";

import { I18n } from "./i18n";

import "./fonts/fonts.css";
import "./index.css";

import registerServiceWorker from "./registerServiceWorker";

import { StorageAdapterLocal } from "./storage";

import { I18nextInitializer } from "./i18n";

import { AuthAPIImpl } from "./api/auth";

import { APIErrorHandler, HTTPAdapterAxios } from "./api";
import { WindowLocationProviderDefault } from "./window-location-provider";

import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";

import { AppContext } from "./app-context";

import { LayoutHelper } from "@ubik.io/ubik-design-system-react";

const storageAdapter = new StorageAdapterLocal();

function buildRootURL(): string {
	return (
		window.location.protocol +
		"//" +
		window.location.hostname +
		(window.location.port ? ":" + window.location.port : "")
	);
}

function buildAppContext(i18n: I18n): AppContext {
	const appContext = new AppContext();

	// Storage
	appContext.storage = storageAdapter;

	// AuthAPI
	const authAPI = new AuthAPIImpl(new HTTPAdapterAxios(), new APIErrorHandler());
	appContext.api.auth = authAPI;

	// i18n
	appContext.i18n = i18n;

	// Root URL
	appContext.rootURL = buildRootURL();

	// Config
	appContext.config.OAuth2.Google.AppID = "954174824619-3553of27po7m9ha1gu0ehmpapeavn2bt.apps.googleusercontent.com";
	appContext.config.OAuth2.Microsoft.AppID = "82dfaa15-5331-4bc2-bc8c-fd1b4138d55c";
	appContext.config.ReCaptcha.SiteKey = "6LdK_CMUAAAAAHijAuxkoQZ_Lwiljw0YI9DpBXM0";

	// Layout size
	appContext.layoutSize = LayoutHelper.sizeFromWidth(window.innerWidth);

	// Window location provider
	appContext.windowLocation = new WindowLocationProviderDefault();

	return appContext;
}

function doStart(appContext: AppContext) {
	ReactDOM.render(
		<BrowserRouter>
			<App appContext={appContext} />
		</BrowserRouter>,
		document.getElementById("root") as HTMLElement
	);

	registerServiceWorker();
}

function init() {
	const i18n = new I18nextInitializer();
	i18n
		.init(storageAdapter)
		.then((i18n: I18n) => {
			const appContext = buildAppContext(i18n);
			doStart(appContext);
		})
		.catch((error) => {
			console.error("Unable to start the app: " + error);
		});
}

init();
