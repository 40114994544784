import { UUID } from "../uuid";

export class APIErrorHandler {
	private registeredHandlers = new Map<string, IAPIErrorRegistree>();

	public pushError(code: number, msg: string): void {
		this.registeredHandlers.forEach((value, key) => {
			value.handleError(code, msg);
		});
	}

	public registerHandler(h: IAPIErrorRegistree): string {
		const id = UUID.NewV4();
		this.registeredHandlers.set(id, h);
		return id;
	}

	public unregister(k: string): boolean {
		return this.registeredHandlers.delete(k);
	}
}

export interface IAPIErrorRegistree {
	handleError(code: number, msg: string): void;
}
