export class UUID {
	public static NewV4(): string {
		try {
			const result = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c: any) => {
				const hasCrypto = window !== undefined && window.crypto !== undefined;
				if (!hasCrypto) {
					throw new Error("'crypto' is not enabled on this system");
				}

				const randValues = window.crypto.getRandomValues(new Uint8Array(1));
				if (randValues === null) {
					throw new Error("Received nil value from 'crypto'");
				}

				/* tslint:disable:no-bitwise */
				return (c ^ (randValues[0] & (15 >> (c / 4)))).toString(16);
				/* tslint:enable:no-bitwise */
			});
			return result;
		} catch (e) {
			return this.v4Fallback();
		}
	}

	private static v4FallbackCounter = 0;

	private static v4Fallback(): string {
		this.v4FallbackCounter++;
		return "uuid-" + this.v4FallbackCounter;
	}
}
