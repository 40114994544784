import { IWindowLocationProvider } from "./window-location-provider";

export class WindowLocationProviderStub implements IWindowLocationProvider {
	public setHrefFunc: (redirectURI: string) => void;
	public reloadFunc: () => void;

	public constructor() {
		this.reloadFunc = () => {
			throw new Error("Not initialized");
		};
		this.setHrefFunc = () => {
			throw new Error("Not initialized");
		};
	}

	public setHref(redirectURI: string): void {
		this.setHrefFunc(redirectURI);
	}

	public reload(): void {
		this.reloadFunc();
	}
}
