import { IProviderI18n } from "./provider";

export class IProviderI18nStub implements IProviderI18n {
	public tFunc: (key: string, ...args: any[]) => string;

	public constructor() {
		this.tFunc = () => {
			throw new Error("Not initialized");
		};
	}

	public t(k: string, ...args: any[]): string {
		return this.tFunc(k, args);
	}
}
