import { ConfigOAuth2Google } from "./config-oauth2-google";
import { ConfigOAuth2Microsoft } from "./config-oauth2-microsoft";

export class ConfigOAuth2 {
	public Google: ConfigOAuth2Google;
	public Microsoft: ConfigOAuth2Microsoft;

	public constructor() {
		this.Google = new ConfigOAuth2Google();
		this.Microsoft = new ConfigOAuth2Microsoft();
	}
}
