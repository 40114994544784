export interface IWindowLocationProvider {
	setHref(redirectURI: string): void;
	reload(): void;
}

export class WindowLocationProviderDefault {
	public setHref(redirectURI: string): void {
		window.location.href = redirectURI;
	}

	public reload(): void {
		window.location.reload();
	}
}
