/*eslint default-case: 0*/

import * as React from "react";

import { Route, Navigate } from "react-router-dom";

import { Routes as ReactRoutes } from "react-router-dom";

import { LoginRoutes } from ".";

import { AppContext } from "../app-context";

import { Header } from "./header";

import { SignInOAuthController } from "./sign-in-oauth/controller";
import { SignInController } from "./sign-in/controller";
import { SignUpConfirmController } from "./sign-up-confirm/controller";
import { SignUpController } from "./sign-up/controller";

import { ForgotPasswordController } from "./forgot-password";
import { ForgotPasswordCallbackController } from "./forgot-password-callback";

import { Transition, TransitionStyleFade } from "@ubik.io/ubik-design-system-react";

import UsersLoginLogo from "./users-login-img.gif";

import { ReCAPTCHA } from "./recaptcha";

import { IReCAPTCHAHost, IReCAPTCHAProvider, ReCAPTCHAProvider, ReCAPTCHAReceiver } from "../recaptcha";

import { SignInOAuthHelper } from "./sign-in-oauth";

import { URLQuery } from "../url";

import styled from "styled-components";

interface IProps {
	appContext: AppContext;
	onSignInComplete: () => void;
	onLanguageChange: (lang: string) => void;
	redirectURI: string;
}

interface IState {
	phase: Phase;
}

export enum Phase {
	RECAPTCHA_INIT,
	LOADED,
}

const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
`;

const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	justify-content: center;
	padding-top: 25px;
`;

const Card = styled.div`
	position: relative;
	color: #000;
	border-radius: 4px;
	box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
	border: solid 1px #282828;
	padding: 5em 40px 45px 40px;
	width: 600px;
	background-color: #fff;

	@media (max-width: 600px) {
		width: 100%;
	}
`;

const TopImageContainer = styled.div`
	text-align: center;
`;

export class LoginController extends React.Component<IProps, IState> implements IReCAPTCHAHost {
	private recaptchaRef = React.createRef<ReCAPTCHA>();
	private recaptchaTimeout: ReturnType<typeof setTimeout> | null;
	private registeredReCAPTCHAReceiver: ReCAPTCHAReceiver | null;
	private recaptchaProvider: IReCAPTCHAProvider;

	public constructor(props: IProps) {
		super(props);
		this.state = {
			phase: Phase.RECAPTCHA_INIT,
		};

		this.recaptchaTimeout = null;
		this.registeredReCAPTCHAReceiver = null;

		this.recaptchaProvider = new ReCAPTCHAProvider(this);

		this.onReCAPTCHALoaded = this.onReCAPTCHALoaded.bind(this);
		this.onReCAPTCHAChange = this.onReCAPTCHAChange.bind(this);
	}

	public onReCAPTCHALoaded(): void {
		this.setState({
			phase: Phase.LOADED,
		});
	}

	public getReCAPTCHAValue(receiver: ReCAPTCHAReceiver): void {
		if (this.recaptchaRef !== null && this.recaptchaRef.current !== null) {
			this.registeredReCAPTCHAReceiver = receiver;

			this.recaptchaRef.current.execute();

			this.recaptchaTimeout = setTimeout(() => {
				this.recaptchaTimeout = null;

				receiver.callback(null);

				if (this.recaptchaRef !== null && this.recaptchaRef.current !== null) {
					this.recaptchaRef.current.reset();
				}
			}, 30000);
			return;
		}

		receiver.callback(null);
	}

	public onReCAPTCHAChange(value: string): void {
		if (this.recaptchaTimeout !== null) {
			clearTimeout(this.recaptchaTimeout);
			this.recaptchaTimeout = null;
		}

		if (this.registeredReCAPTCHAReceiver !== null) {
			this.registeredReCAPTCHAReceiver.callback(value);
		}

		this.registeredReCAPTCHAReceiver = null;

		if (this.recaptchaRef !== null && this.recaptchaRef.current !== null) {
			this.recaptchaRef.current.reset();
		}
	}

	public render(): JSX.Element {
		return (
			<div>
				{this.renderPhase()}
				<ReCAPTCHA
					ref={this.recaptchaRef}
					siteKey={this.props.appContext.config.ReCaptcha.SiteKey}
					onloadCallback={this.onReCAPTCHALoaded}
					verifyCallback={this.onReCAPTCHAChange}
				/>
			</div>
		);
	}

	private renderPhase(): React.ReactNode {
		switch (this.state.phase) {
			case Phase.LOADED:
				return this.renderLoadedPhase();
		}
		return null;
	}

	private renderLoadedPhase(): JSX.Element {
		return (
			<LoginContainer>
				<Header appContext={this.props.appContext} onLanguageChange={this.props.onLanguageChange} />

				<CardContainer>
					<Card>
						<TopImageContainer>
							<img src={UsersLoginLogo} alt="Logo" />
						</TopImageContainer>
						{this.renderRoute()}
					</Card>
				</CardContainer>
			</LoginContainer>
		);
	}

	private renderRoute(): JSX.Element {
		if (this.needsOAuthCallbackRedirection()) {
			const redirectTo = LoginRoutes.SignInOAuthCallback + this.props.appContext.location.search;
			return <Navigate to={redirectTo} />;
		}

		const s = this.props.appContext.layoutSize;
		const transitionKey = Transition.encodeKey(this.props.appContext.location.pathname, s);

		return (
			<Transition transitionStyle={TransitionStyleFade}>
				<ReactRoutes location={this.props.appContext.location} key={transitionKey}>
					<Route
						path={LoginRoutes.toRelativePath(LoginRoutes.Root, LoginRoutes.SignUp + "/*")}
						element={this.renderSignUpController()}
					/>
					<Route
						path={LoginRoutes.toRelativePath(LoginRoutes.Root, LoginRoutes.SignUpConfirm + "/*")}
						element={this.renderSignUpConfirmController()}
					/>
					<Route
						path={LoginRoutes.toRelativePath(LoginRoutes.Root, LoginRoutes.SignIn + "/*")}
						element={this.renderSignInController()}
					/>
					<Route
						path={LoginRoutes.toRelativePath(LoginRoutes.Root, LoginRoutes.SignInOAuthCallback + "/*")}
						element={this.renderSignInOAuthController()}
					/>
					<Route
						path={LoginRoutes.toRelativePath(LoginRoutes.Root, LoginRoutes.ForgotPassword + "/*")}
						element={this.renderForgotPassword()}
					/>
					<Route
						path={LoginRoutes.toRelativePath(LoginRoutes.Root, LoginRoutes.ForgotPasswordCallback + "/*")}
						element={this.renderForgotPasswordCallback()}
					/>
					<Route path="/" element={this.renderSignInController()} />
				</ReactRoutes>
			</Transition>
		);
	}

	// OAuth callbacks cannot have custom parameters in redirect_uri. The only thing we can do is include a state parameter
	// that will be added back in the redirect_uri from OAuth services. We do a base64 encoding of a state object
	// to allow passing new data in the future
	private needsOAuthCallbackRedirection(): boolean {
		const stateParams = URLQuery.findParam(this.props.appContext.location.search, "state");
		const state = SignInOAuthHelper.decodeOAuthState(stateParams);

		// Will be null
		// - if no state is available
		// - if state does not contain a valid oAuthProvider parameter
		// - if state does not contain callbackTo=/login/sign
		if (state === null) {
			return false;
		}

		// We need to move to the proper route
		if (this.props.appContext.location.pathname !== LoginRoutes.SignInOAuthCallback) {
			return true;
		}

		return false;
	}

	private renderSignInController(): JSX.Element {
		return (
			<SignInController
				appContext={this.props.appContext}
				onSignInComplete={this.props.onSignInComplete}
				redirectURI={this.props.redirectURI}
			/>
		);
	}

	private renderSignUpController(): JSX.Element {
		return (
			<SignUpController
				appContext={this.props.appContext}
				reCAPTCHA={this.recaptchaProvider}
				redirectURI={this.props.redirectURI}
			/>
		);
	}

	private renderSignUpConfirmController(): JSX.Element {
		return (
			<SignUpConfirmController
				appContext={this.props.appContext}
				reCAPTCHA={this.recaptchaProvider}
				onSignInComplete={this.props.onSignInComplete}
			/>
		);
	}

	private renderSignInOAuthController(): JSX.Element {
		return <SignInOAuthController appContext={this.props.appContext} onSignInComplete={this.props.onSignInComplete} />;
	}

	private renderForgotPassword(): JSX.Element {
		return (
			<ForgotPasswordController
				appContext={this.props.appContext}
				reCAPTCHA={this.recaptchaProvider}
				redirectURI={this.props.redirectURI}
			/>
		);
	}

	private renderForgotPasswordCallback(): JSX.Element {
		return (
			<ForgotPasswordCallbackController
				appContext={this.props.appContext}
				reCAPTCHA={this.recaptchaProvider}
				onSignInComplete={this.props.onSignInComplete}
			/>
		);
	}
}
