import axios, { AxiosError, AxiosResponse } from "axios";

import { IHTTPAdapter, IHTTPError, IHTTPResponse } from "./http-adapter";

export class HTTPAdapterAxios implements IHTTPAdapter {
	public get(url: string, headers?: Record<string, string | number | boolean>): Promise<IHTTPResponse> {
		return new Promise<IHTTPResponse>((resolve, reject) => {
			axios
				.get(url, { headers: headers })
				.then((response: AxiosResponse) => {
					resolve(this.convertAxiosResponse(response));
				})
				.catch((error: AxiosError) => {
					reject(this.convertAxiosError(error));
				});
		});
	}

	public post(url: string, data?: string, headers?: Record<string, string | number | boolean>): Promise<IHTTPResponse> {
		return new Promise<IHTTPResponse>((resolve, reject) => {
			axios
				.post(url, data, { headers: headers })
				.then((response: AxiosResponse) => {
					resolve(this.convertAxiosResponse(response));
				})
				.catch((error: AxiosError) => {
					reject(this.convertAxiosError(error));
				});
		});
	}

	public patch(
		url: string,
		data?: string,
		headers?: Record<string, string | number | boolean>
	): Promise<IHTTPResponse> {
		return new Promise<IHTTPResponse>((resolve, reject) => {
			axios
				.patch(url, data, { headers: headers })
				.then((response: AxiosResponse) => {
					resolve(this.convertAxiosResponse(response));
				})
				.catch((error: AxiosError) => {
					reject(this.convertAxiosError(error));
				});
		});
	}

	public delete(url: string, headers?: Record<string, string | number | boolean>): Promise<IHTTPResponse> {
		return new Promise<IHTTPResponse>((resolve, reject) => {
			axios
				.delete(url, { headers: headers })
				.then((response: AxiosResponse) => {
					resolve(this.convertAxiosResponse(response));
				})
				.catch((error: AxiosError) => {
					reject(this.convertAxiosError(error));
				});
		});
	}

	private convertAxiosResponse(response: AxiosResponse): IHTTPResponse {
		return {
			data: response.data,
			headers: response.headers,
			request: response.request,
			status: response.status,
			statusText: response.statusText,
		};
	}

	private convertAxiosError(error: AxiosError): IHTTPError {
		let finalResponse: IHTTPResponse | undefined;
		if (error.response !== undefined) {
			finalResponse = this.convertAxiosResponse(error.response);
		}

		return {
			request: error.request,
			response: finalResponse,
		};
	}
}
