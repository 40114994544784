import { LoginRoutes } from "../routes";

export interface IOAuthState {
	oauth_provider: "google" | "microsoft";
	callback_to_endpoint: string;
	nonce: string;
	redirect_uri: string;
}

export class SignInOAuthHelper {
	public static encodeOAuthState(state: IOAuthState): string {
		return btoa(JSON.stringify(state));
	}

	public static decodeOAuthState(stateParam: string | null): IOAuthState | null {
		if (stateParam === null) {
			return null;
		}

		// Try to base64 decode the state
		let stateJSONString = "";
		try {
			stateJSONString = atob(stateParam as string);
		} catch (e) {
			return null;
		}

		// Decode the JSON string as an object
		let state: any;
		try {
			state = JSON.parse(stateJSONString);
		} catch (e) {
			return null;
		}

		// Try to find the oauth_provider paramter
		if (state.oauth_provider === undefined) {
			return null;
		}
		if (!(state.oauth_provider === "google" || state.oauth_provider === "microsoft")) {
			return null;
		}

		// Try to find callbackTo parameter
		if (state.callback_to_endpoint === undefined) {
			return null;
		}
		if (state.callback_to_endpoint !== LoginRoutes.SignInOAuthCallback) {
			return null;
		}

		if (state.redirect_uri === undefined) {
			state.redirect_uri = "";
		}

		return {
			callback_to_endpoint: state.callback_to_endpoint,
			oauth_provider: state.oauth_provider,
			nonce: state.nonce,
			redirect_uri: state.redirect_uri,
		};
	}
}
