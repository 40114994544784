import { IReCAPTCHAHost, ReCAPTCHAReceiver } from ".";

export interface IReCAPTCHAProvider {
	getReCAPTCHAValue(): Promise<string>;
}

export class ReCAPTCHAProvider implements IReCAPTCHAProvider {
	private host: IReCAPTCHAHost;

	public constructor(host: IReCAPTCHAHost) {
		this.host = host;
	}

	public getReCAPTCHAValue(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const receiver = new ReCAPTCHAReceiver((value: string | null) => {
				if (value === null) {
					reject("ReCAPTCHA was never initalized");
				}
				resolve(value as string);
			});
			this.host.getReCAPTCHAValue(receiver);
		});
	}
}
