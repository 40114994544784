import { APIErrorHandler } from "..";

import {
	IAuthAPI,
	IConfirmUserRequest,
	ISignInRequest,
	ISignUpRequest,
	IForgotPasswordRequest,
	ISignInResponse,
} from ".";

import { Scope } from "./scope";

import { IHTTPAdapter, IHTTPError, IHTTPResponse } from "..";

export class AuthAPIImpl implements IAuthAPI {
	public static REFRESH_TOKEN_STORAGE_KEY = "api.v1.auth.refresh_token";

	private static ENDPOINT = "/api/v1/auth";

	private http: IHTTPAdapter;
	public errorHandler: APIErrorHandler;

	public constructor(http: IHTTPAdapter, errorHandler: APIErrorHandler) {
		this.http = http;
		this.errorHandler = errorHandler;
	}

	public signIn(req: ISignInRequest): Promise<ISignInResponse> {
		return new Promise<ISignInResponse>((resolve, reject) => {
			this.http
				.post(AuthAPIImpl.ENDPOINT + "/signin", JSON.stringify(req))
				.then((response: IHTTPResponse) => {
					if (response === undefined) {
						return this.handleError(500, "Undefined HTTP response", reject);
					}

					let msg = "";
					switch (response.status) {
						case 201:
							if (response.data !== undefined) {
								// The refresh token is also set in an http only cookie so we don't need to store it anywhere
								return resolve(response.data);
							}

							msg = JSON.stringify(response);
							return this.handleError(500, "Impossible to read response from server: " + msg, reject);
						default:
							msg = JSON.stringify(response.data);
							return this.handleError(response.status, msg, reject);
					}
				})
				.catch((error: IHTTPError) => {
					return this.handleHTTPError(error, reject);
				});
		});
	}

	public getAccessToken(scopeList: Scope[]): Promise<string> {
		const encodedScopeList: string[] = [];

		for (const scope of scopeList) {
			const encoded = scope.encode();
			encodedScopeList.push(encoded);
		}

		return new Promise((resolve, reject) => {
			this.http
				.post(
					AuthAPIImpl.ENDPOINT + "/token/access",
					// Refresh token is set in an HTTP only cookie. HTTP endpoint will know how to use it
					JSON.stringify({
						nonce: Date.now().toString(),
						scope_list: encodedScopeList,
					})
				)
				.then((response: IHTTPResponse) => {
					if (response === undefined) {
						return this.handleError(500, "Undefined HTTP response", reject);
					}

					let msg = "";
					switch (response.status) {
						case 201:
							if (response.data !== undefined && response.data.access_token !== undefined) {
								return resolve(response.data.access_token);
							}

							msg = JSON.stringify(response);
							return this.handleError(500, "Impossible to read response from server: " + msg, reject);
						default:
							msg = JSON.stringify(response.data);
							return this.handleError(response.status, msg, reject);
					}
				})
				.catch((error: IHTTPError) => {
					return this.handleHTTPError(error, reject);
				});
		});
	}

	public signUp(req: ISignUpRequest): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.post(AuthAPIImpl.ENDPOINT + "/signup", JSON.stringify(req))
				.then((response: IHTTPResponse) => {
					if (response === undefined) {
						return this.handleError(500, "Undefined HTTP response", reject);
					}

					let msg = "";
					switch (response.status) {
						case 201:
							if (response.data !== undefined) {
								return resolve();
							}

							msg = JSON.stringify(response);
							return this.handleError(500, "Impossible to read response from server: " + msg, reject);
						default:
							msg = JSON.stringify(response.data);
							return this.handleError(response.status, msg, reject);
					}
				})
				.catch((error: IHTTPError) => {
					return this.handleHTTPError(error, reject);
				});
		});
	}

	public confirmUser(req: IConfirmUserRequest): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			this.http
				.post(AuthAPIImpl.ENDPOINT + "/user/confirm", JSON.stringify(req))
				.then((response: IHTTPResponse) => {
					if (response === undefined) {
						return this.handleError(500, "Undefined HTTP response", reject);
					}

					let msg = "";
					switch (response.status) {
						case 201:
							if (response.data !== undefined && response.data.email !== undefined) {
								return resolve(response.data.email);
							}

							msg = JSON.stringify(response);
							return this.handleError(500, "Impossible to read response from server: " + msg, reject);
						default:
							msg = JSON.stringify(response.data);
							return this.handleError(response.status, msg, reject);
					}
				})
				.catch((error: IHTTPError) => {
					return this.handleHTTPError(error, reject);
				});
		});
	}
	// /api/v1/auth/user/{b64Email}/password/forgot
	// AuthAPIImpl.ENDPOINT + "/user/" + req.email_base64, req, {
	public forgotPassword(req: IForgotPasswordRequest): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.http
				.post(AuthAPIImpl.ENDPOINT + "/user/" + req.email_base64 + "/password/forgot", JSON.stringify(req))
				.then((response: IHTTPResponse) => {
					if (response === undefined) {
						return this.handleError(500, "Undefined HTTP response", reject);
					}

					let msg = "";
					switch (response.status) {
						case 201:
							if (response.data !== undefined) {
								return resolve();
							}

							msg = JSON.stringify(response);
							return this.handleError(500, "Impossible to read response from server: " + msg, reject);
						default:
							msg = JSON.stringify(response.data);
							return this.handleError(response.status, msg, reject);
					}
				})
				.catch((error: IHTTPError) => {
					return this.handleHTTPError(error, reject);
				});
		});
	}

	private handleHTTPError(error: IHTTPError, reject: (code: number) => void) {
		if (error === undefined || error.response === undefined) {
			return this.handleError(500, JSON.stringify(error), reject);
		}

		return this.handleError(error.response.status, error.response.data, reject);
	}

	private handleError(code: number, msg: string, reject: (code: number) => void) {
		this.errorHandler.pushError(code, msg);
		reject(code);
	}
}
