import { IStorageAdapter } from "./storage-adapter";

export class StorageAdapterStub implements IStorageAdapter {
	public getFunc: (key: string) => string | null;
	public putFunc: (key: string, value: string) => void;
	public delFunc: (key: string) => void;

	public constructor() {
		this.getFunc = () => {
			throw new Error("Not initialized");
		};
		this.putFunc = () => {
			throw new Error("Not initialized");
		};
		this.delFunc = () => {
			throw new Error("Not initialized");
		};
	}

	public get(key: string): string | null {
		return this.getFunc(key);
	}

	public put(key: string, value: string): void {
		return this.putFunc(key, value);
	}

	public del(key: string): void {
		return this.delFunc(key);
	}
}
