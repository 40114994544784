import { ConfigOAuth2 } from "./config-oauth2";
import { ConfigReCaptcha } from "./config-recaptcha";

export class Config {
	public OAuth2: ConfigOAuth2;
	public ReCaptcha: ConfigReCaptcha;

	public constructor() {
		this.OAuth2 = new ConfigOAuth2();
		this.ReCaptcha = new ConfigReCaptcha();
	}
}
