/*eslint default-case: 0*/
import * as React from "react";

import { AppContext } from "./app-context";

import { LoginController } from "./components";

import { Routes as ReactRoutes } from "react-router-dom";

import { Route, Navigate, useLocation, useNavigate } from "react-router-dom";

import { LoginRoutes } from "./components/routes";

import { URLQuery } from "./url";

import { SignInOAuthHelper } from "./components/sign-in-oauth";

import { LayoutHelper, Snackbar } from "@ubik.io/ubik-design-system-react";

interface IProps {
	appContext: AppContext;
}

interface IState {
	appContext: AppContext;
	redirectURI: string;
}

export class AppClass extends React.Component<IProps, IState> {
	public static readonly DEFAULT_REDIRECT_URI = "/manage";

	public constructor(props: IProps) {
		super(props);

		this.onLanguageChange = this.onLanguageChange.bind(this);
		this.onSignInComplete = this.onSignInComplete.bind(this);
		this.onWindowResize = this.onWindowResize.bind(this);

		this.state = {
			appContext: props.appContext,
			redirectURI: this.findRedirectURI(),
		};
	}

	public componentDidMount(): void {
		window.addEventListener("resize", this.onWindowResize);
	}

	public componentWillUnmount(): void {
		window.removeEventListener("resize", this.onWindowResize);
	}

	public onWindowResize(): void {
		const newSize = LayoutHelper.sizeFromWidth(window.innerWidth);
		if (newSize === this.state.appContext.layoutSize) {
			return;
		}
		const appContext = this.state.appContext;
		appContext.layoutSize = newSize;

		this.setState({
			appContext,
		});
	}

	public onLanguageChange(lang: string): void {
		this.state.appContext.i18n.setLanguage(this.state.appContext.storage, lang);
		this.state.appContext.windowLocation.reload();
	}

	public onSignInComplete(): void {
		let redirectURI = this.state.redirectURI;

		if (redirectURI === "") {
			redirectURI = AppClass.DEFAULT_REDIRECT_URI;
		}

		this.state.appContext.windowLocation.setHref(redirectURI);
	}

	public render(): JSX.Element {
		return (
			<ReactRoutes>
				<Route path={LoginRoutes.Root + "/*"} element={this.renderLoginController()} />
				<Route element={this.renderNotFoundRoute()} />
			</ReactRoutes>
		);
	}

	private renderLoginController(): JSX.Element {
		const s = this.state.appContext.layoutSize;
		return (
			<>
				<LoginController
					appContext={this.state.appContext}
					onSignInComplete={this.onSignInComplete}
					onLanguageChange={this.onLanguageChange}
					redirectURI={this.state.redirectURI}
				/>
				<Snackbar size={s} ref={this.state.appContext.snackbarRef} />
			</>
		);
	}

	private renderNotFoundRoute(): JSX.Element {
		const redirectTo = LoginRoutes.Root + this.props.appContext.location.search;
		return <Navigate to={redirectTo} />;
	}

	/**
	 * This function will try to find a redirect_uri from the URL parameters.
	 * It will also look into the state URL paramter so OAuth logins can be redirected as well.
	 *
	 * It will return an empty string if not found
	 */
	private findRedirectURI(): string {
		const fromURLParams = URLQuery.findParam(this.props.appContext.location.search, "redirect_uri");
		if (fromURLParams !== null) {
			return fromURLParams;
		}

		// Sometimes, redirect_uri can be found encoded in the state parame (when using OAuth)
		const oAuthStateEncoded = URLQuery.findParam(this.props.appContext.location.search, "state");
		const oAuthState = SignInOAuthHelper.decodeOAuthState(oAuthStateEncoded);
		if (oAuthState != null) {
			return oAuthState.redirect_uri;
		}

		return "";
	}
}

interface IAppProps {
	appContext: AppContext;
}

export function App(props: IAppProps): JSX.Element {
	props.appContext.location = useLocation();
	props.appContext.navigate = useNavigate();

	return <AppClass appContext={props.appContext} />;
}
