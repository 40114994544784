import * as React from "react";

import { AppContext } from "../../app-context";

import { LoginRoutes } from "../";

import { Validator } from "../../validator";

import { IReCAPTCHAProvider } from "../../recaptcha";

import { ButtonContained, ButtonText, LoadingIndeterminate, TextField, Typo } from "@ubik.io/ubik-design-system-react";

import styled from "styled-components";

interface IProps {
	appContext: AppContext;
	reCAPTCHA: IReCAPTCHAProvider;
	email: string;
	onForgotPasswordComplete: (email: string) => void;
	redirectURI: string;
}

interface IState {
	email: string;
	emailErrorMessage: string;
	requestInProgress: boolean;
	phase: Phase;
}

enum Phase {
	Form,
}

const MessageContainer = styled.div`
	${Typo.Body}

	text-align: center;
	margin: 32px 0 56px 0;
`;

const FormContainer = styled.div`
	margin: 0 auto 24px auto;
	max-width: 380px;
`;

const SubmitButtonContainer = styled.div`
	max-width: 380px;
	margin: 0 auto;
`;

const Footer = styled.div`
	margin: 24px auto 0;
	max-width: 380px;
`;

const LoadingContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`;

export class ForgotPasswordForm extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			email: props.email,
			emailErrorMessage: "",
			requestInProgress: false,
			phase: Phase.Form,
		};

		this.onEmailChange = this.onEmailChange.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.onSubmitClick = this.onSubmitClick.bind(this);
		this.handleError = this.handleError.bind(this);
		this.onSignInClick = this.onSignInClick.bind(this);
	}

	public onEmailChange(email: string): void {
		this.setState({
			email,
		});
	}

	public onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void {
		if (e.key === "Enter") {
			this.submit();
		}
	}

	public onSubmitClick(): void {
		this.submit();
	}

	public onSignInClick(): void {
		this.props.appContext.navigate(LoginRoutes.SignIn);
	}

	public render(): JSX.Element {
		switch (this.state.phase) {
			case Phase.Form:
				return this.renderForm();
		}
	}

	public renderForm(): JSX.Element {
		const s = this.props.appContext.layoutSize;

		return (
			<>
				{this.renderLoading()}
				<MessageContainer size={s}>
					{this.props.appContext.i18n.t("components.forget-password.form.message")}
				</MessageContainer>

				<FormContainer>
					<TextField
						size={s}
						type="email"
						label={this.props.appContext.i18n.t("components.forget-password.form.emailTextField")}
						focus={true}
						onValueChange={this.onEmailChange}
						onKeyPress={this.onKeyPress}
						errorMessage={this.state.emailErrorMessage}
						value={this.state.email}
						disabled={this.state.requestInProgress}
					/>
				</FormContainer>

				<SubmitButtonContainer>
					<ButtonContained
						size={s}
						text={this.props.appContext.i18n.t("components.forget-password.form.resetPasswordButton")}
						onClick={this.onSubmitClick}
						disabled={this.state.requestInProgress}
					/>
				</SubmitButtonContainer>

				<Footer>
					<ButtonText
						size={s}
						text={this.props.appContext.i18n.t("components.forget-password.form.signInButton")}
						onClick={this.onSignInClick}
						disabled={this.state.requestInProgress}
					/>
				</Footer>
			</>
		);
	}

	private renderLoading() {
		if (!this.state.requestInProgress) {
			return null;
		}
		return (
			<LoadingContainer>
				<LoadingIndeterminate />
			</LoadingContainer>
		);
	}

	private submit(): void {
		let valid = true;
		let emailMsg = "";

		if (!Validator.isNotEmpty(this.state.email)) {
			valid = false;
			emailMsg = this.props.appContext.i18n.t("components.forget-password.form.validation.emailCannotBeEmpty");
		}
		if (valid && !Validator.isEmail(this.state.email)) {
			valid = false;
			emailMsg = this.props.appContext.i18n.t("components.forget-password.form.validation.invalidEmailAdress");
		}

		this.setState({
			emailErrorMessage: emailMsg,
			requestInProgress: valid,
		});

		if (valid) {
			this.doSubmit();
		}
	}

	private doSubmit(): void {
		this.props.reCAPTCHA
			.getReCAPTCHAValue()
			.then((recaptchaValue: string) => {
				const confirmURL = this.buildConfirmURL();

				this.props.appContext.api.auth
					.forgotPassword({
						confirm_url: confirmURL,
						email_base64: window.btoa(this.state.email),
						language: this.props.appContext.i18n.getCurrentLanguage(),
						recaptcha_response: recaptchaValue,
					})
					.then((value: void) => {
						this.setState({
							requestInProgress: false,
						});

						this.props.onForgotPasswordComplete(this.state.email);
					})
					.catch(this.handleError);
			})
			.catch(this.handleError);
	}

	private handleError(error: any): void {
		this.setState({ requestInProgress: false });

		switch (error) {
			case 403: {
				this.setState({
					emailErrorMessage: this.props.appContext.i18n.t("components.forget-password.form.validation.emailNotFound"),
				});
				break;
			}
			default: {
				const snackbarRef = this.props.appContext.snackbarRef;
				if (snackbarRef.current !== null) {
					snackbarRef.current.show({
						actionMessage: this.props.appContext.i18n.t("components.forget-password.form.snackbar.tryAgainAction"),
						message: this.props.appContext.i18n.t("components.forget-password.form.snackbar.defaultError"),
						onActionClick: this.onSubmitClick,
					});
				}
				break;
			}
		}
	}

	private buildConfirmURL(): string {
		let url = this.props.appContext.rootURL + LoginRoutes.ForgotPasswordCallback;
		if (this.props.redirectURI !== "") {
			url += "?redirect_uri=" + this.props.redirectURI;
		}

		return url;
	}
}
