import * as React from "react";

import { AppContext } from "../../app-context";

import LogoSrc from "./button-google.svg";

import "./button-google-fonts.css";

import styled from "styled-components";

import { SignInOAuthHelper } from "../sign-in-oauth";

import { OAuth } from "../../oauth";

import { LoginRoutes } from "../";

import { UUID } from "../../uuid";

interface IProps {
	appContext: AppContext;
	disabled: boolean;
	redirectURI: string;
}

interface IContainerProps {
	disabled: boolean;
}
const Container = styled.div<IContainerProps>`
	background-color: #ffffff;
	color: #8d8d8b;
	font-size: 16px;
	height: 50px;
	display: flex;
	font-family: "Roboto Medium", sans-serif;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	transform: scale(1);
	transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s, transform 0.218s;
	border-radius: 2px;

	opacity: ${(props: IContainerProps) => {
		if (props.disabled) {
			return "0.5;";
		}
		return "1;";
	}}

	&:hover {
		box-shadow: ${(props: IContainerProps) => {
			if (props.disabled) {
				return "0 2px 4px 0 rgba(0, 0, 0, 0.25);";
			}
			return "0 5px 10px rgba(47, 47, 47, 0.3);";
		}}
		cursor: ${(props: IContainerProps) => {
			if (props.disabled) {
				return "default !important;";
			}
			return "pointer !important;";
		}}
		transform: ${(props: IContainerProps) => {
			if (props.disabled) {
				return "scale(1.0);";
			}
			return "scale(1.01);";
		}}
	}
`;

const Logo = styled.div`
	width: 48px;
	height: 48px;
	margin-left: 1px;
	align-self: center;
	background-color: #fff;
	display: flex;
`;

const LogoImg = styled.img`
	align-self: center;
	margin-left: 15px;
`;

const Text = styled.div`
	align-self: center;
	text-align: center;
	padding-left: 24px;
	padding-right: 24px;
	flex-grow: 1;
	text-decoration: none !important;
`;

const GOOGLE_OAUTH2_URL = "https://accounts.google.com/o/oauth2/v2/auth";

export class SignInButtonGoogle extends React.Component<IProps, any> {
	public static defaultProps = {
		disabled: false,
	};

	public constructor(props: IProps) {
		super(props);

		this.onClick = this.onClick.bind(this);
	}

	private onClick() {
		if (this.props.disabled) {
			return;
		}

		const state = SignInOAuthHelper.encodeOAuthState({
			oauth_provider: "google",
			callback_to_endpoint: LoginRoutes.SignInOAuthCallback,
			nonce: UUID.NewV4(),
			redirect_uri: this.props.redirectURI,
		});

		const url =
			GOOGLE_OAUTH2_URL +
			"?client_id=" +
			this.props.appContext.config.OAuth2.Google.AppID +
			"&scope=" +
			OAuth.googleGetScope() +
			"&prompt=select_account" +
			"&response_type=code" +
			"&state=" +
			state +
			"&redirect_uri=" +
			this.props.appContext.rootURL;

		this.props.appContext.windowLocation.setHref(url);
	}

	public render(): JSX.Element {
		return (
			<Container disabled={this.props.disabled} onClick={this.onClick} data-name="oauth-google">
				<Logo>
					<LogoImg src={LogoSrc} alt="G" />
				</Logo>
				<Text>{this.props.appContext.i18n.t("components.sign-in.button-google.text")}</Text>
			</Container>
		);
	}
}
