/*eslint default-case: 0*/
import * as React from "react";

import { AppContext } from "../../app-context";

import { Transition, TransitionStyleFade } from "@ubik.io/ubik-design-system-react";

import { SignUpConfirmForm } from "./form";

import { IReCAPTCHAProvider } from "../../recaptcha";
import { LoginRoutes } from "../routes";

enum Phase {
	Form,
}

interface IProps {
	appContext: AppContext;
	reCAPTCHA: IReCAPTCHAProvider;
	onSignInComplete: () => void;
}

interface IState {
	phase: Phase;
}

export class SignUpConfirmController extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			phase: Phase.Form,
		};

		this.onSignInError = this.onSignInError.bind(this);
	}

	public render(): JSX.Element {
		const s = this.props.appContext.layoutSize;
		const transitionKey = Transition.encodeKey(this.state.phase.toString(), s);

		return (
			<Transition transitionStyle={TransitionStyleFade}>
				<div key={transitionKey}>{this.renderPhase()}</div>
			</Transition>
		);
	}

	public onSignInError(): void {
		const snackbarRef = this.props.appContext.snackbarRef;

		if (snackbarRef.current !== null) {
			let msg = "";
			msg += this.props.appContext.i18n.t("components.sign-up-confirm.controller.somethingWentWrong");
			snackbarRef.current?.show({
				message: msg,
			});
		}

		this.props.appContext.navigate(LoginRoutes.SignIn);
	}

	public renderPhase(): JSX.Element {
		switch (this.state.phase) {
			case Phase.Form:
				return (
					<SignUpConfirmForm
						appContext={this.props.appContext}
						onSignInComplete={this.props.onSignInComplete}
						onSignInError={this.onSignInError}
						reCAPTCHA={this.props.reCAPTCHA}
					/>
				);
		}
	}
}
