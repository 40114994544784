import {
	IAuthAPI,
	IConfirmUserRequest,
	IForgotPasswordRequest,
	ISignInRequest,
	ISignUpRequest,
	Scope,
	ISignInResponse,
} from ".";

export class AuthAPIStub implements IAuthAPI {
	public signInFunc: (req: ISignInRequest) => Promise<ISignInResponse>;
	public getAccessTokenFunc: (scopeList: Scope[]) => Promise<string>;
	public signUpFunc: (req: ISignUpRequest) => Promise<void>;
	public confirmUserFunc: (req: IConfirmUserRequest) => Promise<string>;
	public forgotPasswordFunc: (req: IForgotPasswordRequest) => Promise<void>;

	public constructor() {
		this.signInFunc = () => {
			throw new Error("Not initialized");
		};

		this.getAccessTokenFunc = () => {
			throw new Error("Not initialized");
		};

		this.signUpFunc = () => {
			throw new Error("Not initialized");
		};

		this.confirmUserFunc = () => {
			throw new Error("Not initialized");
		};

		this.forgotPasswordFunc = () => {
			throw new Error("Not initialized");
		};
	}

	public signIn(req: ISignInRequest): Promise<ISignInResponse> {
		return this.signInFunc(req);
	}

	public getAccessToken(scopeList: Scope[]): Promise<string> {
		return this.getAccessTokenFunc(scopeList);
	}

	public signUp(req: ISignUpRequest): Promise<void> {
		return this.signUpFunc(req);
	}

	public confirmUser(req: IConfirmUserRequest): Promise<string> {
		return this.confirmUserFunc(req);
	}

	public forgotPassword(req: IForgotPasswordRequest): Promise<void> {
		return this.forgotPasswordFunc(req);
	}
}
