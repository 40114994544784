export class IProviderI18nDefault {
	private languagePack: Map<string, string>;

	public constructor(languagePack: Map<string, string>) {
		this.languagePack = languagePack;
	}

	public t(key: string, ...args: any[]): string {
		const result = this.languagePack.get(key);

		if (result === undefined || result === "") {
			return key;
		}

		if (args.length === 0) {
			return result;
		}

		return this.format(result, ...args);
	}

	private format(text: string, ...params: any[]): string {
		let result: string[] = [text];

		params.forEach((param, i) => {
			result = this.parseAndReplace(result, param, i);
		});

		return result.join("");
	}

	private replaceWhiteSpace(text: string): string[] {
		const result: string[] = [];
		let start = false;
		let end = false;
		if (!!text.match(/^\s+/gi)) {
			text = text.replace(/^\s+/gi, "");
			start = true;
		}
		if (!!text.match(/\s+$/gi)) {
			text = text.replace(/\s+$/gi, "");
			end = true;
		}
		if (start) {
			result.push("&nbsp;");
		}
		result.push(text);
		if (end) {
			result.push("&nbsp;");
		}

		return result;
	}

	private parseAndReplace(source: string[], replaceWith: string, index: number): string[] {
		const result: string[] = [];

		source.forEach((possibleText) => {
			if (typeof possibleText === "string") {
				const pattern = new RegExp(`\\{${index}\\}`, "gi");
				if (typeof replaceWith === "string" || typeof replaceWith === "number") {
					result.push(possibleText.replace(pattern, `${replaceWith}`));
				} else {
					const splits = possibleText.split(pattern);
					splits.forEach((splitText, i) => {
						if (splitText) {
							this.replaceWhiteSpace(splitText).forEach((text) => result.push(text));
						}
						// if NOT last
						if (i + 1 < splits.length) {
							result.push(replaceWith);
						}
					});
				}
			} else {
				result.push(possibleText);
			}
		});

		return result;
	}
}
