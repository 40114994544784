import * as React from "react";

import { AppContext } from "../../app-context";

import { LoadingIndeterminate, Typo } from "@ubik.io/ubik-design-system-react";

import styled from "styled-components";

interface IProps {
	appContext: AppContext;
}

const MessageContainer = styled.div`
	${Typo.Body}

	text-align: center;
	margin: 32px auto 56px auto;
	max-width: 380px;
`;

const LoadingContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`;

export class SignInOAuthChecking extends React.Component<IProps, any> {
	public render(): JSX.Element {
		const s = this.props.appContext.layoutSize;

		return (
			<>
				<LoadingContainer>
					<LoadingIndeterminate />
				</LoadingContainer>
				<MessageContainer size={s}>
					{this.props.appContext.i18n.t("components.sign-in-oauth.checking.pleaseWait")}
				</MessageContainer>
			</>
		);
	}
}
