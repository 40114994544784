/*eslint default-case: 0*/
import * as React from "react";

import { AppContext } from "../../app-context";

import { SignUpFormThankYou } from "./thank-you";

import { SignUpForm } from "./form";

import { Transition, TransitionStyleFade } from "@ubik.io/ubik-design-system-react";

import { IReCAPTCHAProvider } from "../../recaptcha";

import { LoginRoutes } from "../";

enum Phase {
	Form,
	ThankYou,
}

interface IProps {
	appContext: AppContext;
	reCAPTCHA: IReCAPTCHAProvider;
	redirectURI: string;
}

interface IState {
	phase: Phase;
	fullname: string;
	email: string;
}

export class SignUpController extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			email: "",
			fullname: "",
			phase: Phase.Form,
		};

		this.onSignUpComplete = this.onSignUpComplete.bind(this);
		this.onTryAgainClick = this.onTryAgainClick.bind(this);
		this.renderPhase = this.renderPhase.bind(this);
		this.onUserAlreadyExists = this.onUserAlreadyExists.bind(this);
	}

	public onTryAgainClick(): void {
		this.setState({
			phase: Phase.Form,
		});
	}

	public onSignUpComplete(fullname: string, email: string): void {
		this.setState({
			email,
			fullname,
			phase: Phase.ThankYou,
		});
	}

	public onUserAlreadyExists(email: string): void {
		const msg = this.props.appContext.i18n.t("components.sign-up.controller.validation.emailAlreadyExists");

		const actionMsg = this.props.appContext.i18n.t("components.sign-up.controller.validation.signInAction");

		const actionClick = () => {
			this.props.appContext.navigate(LoginRoutes.SignIn);
		};

		const snackbarRef = this.props.appContext.snackbarRef;
		if (snackbarRef.current !== null) {
			snackbarRef.current?.show({
				message: msg,
				actionMessage: actionMsg,
				onActionClick: actionClick,
			});
		}
	}

	public render(): JSX.Element {
		const s = this.props.appContext.layoutSize;
		const transitionKey = Transition.encodeKey(this.state.phase.toString(), s);

		return (
			<Transition transitionStyle={TransitionStyleFade}>
				<div key={transitionKey}>{this.renderPhase()}</div>
			</Transition>
		);
	}

	public renderPhase(): JSX.Element {
		switch (this.state.phase) {
			case Phase.ThankYou:
				return (
					<SignUpFormThankYou
						appContext={this.props.appContext}
						email={this.state.email}
						onTryAgainClick={this.onTryAgainClick}
					/>
				);

			case Phase.Form:
				return (
					<SignUpForm
						appContext={this.props.appContext}
						email={this.state.email}
						fullname={this.state.fullname}
						onSignUpComplete={this.onSignUpComplete}
						onUserAlreadyExists={this.onUserAlreadyExists}
						reCAPTCHA={this.props.reCAPTCHA}
						redirectURI={this.props.redirectURI}
					/>
				);
		}
	}
}
