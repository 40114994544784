import { I18nBrowserHelper } from "./browser-helper";

import { IStorageAdapter } from "../storage";

export class I18nHelper {
	public static readonly DEFAULT_LANG = "fr";
	public static readonly LOCAL_STORAGE_KEY = "lang";

	public static getBestLanguage(storageAdapter: IStorageAdapter): string {
		// -1 Try from storage
		const storeLang = storageAdapter.get(I18nHelper.LOCAL_STORAGE_KEY);
		if (storeLang !== null && storeLang !== "") {
			return this.sanitizeLang(storeLang);
		}

		// -2 Try navigator language
		const navLang = I18nBrowserHelper.getNavigatorLang();
		if (navLang !== null && navLang !== "") {
			return this.sanitizeLang(navLang);
		}

		// -3 Fallback to default language
		return I18nHelper.DEFAULT_LANG;
	}

	public static saveLanguage(storageAdapter: IStorageAdapter, lang: string): void {
		storageAdapter.put(I18nHelper.LOCAL_STORAGE_KEY, lang);
	}

	public static sanitizeLang(lang: string): string {
		return lang.split("-")[0];
	}
}
