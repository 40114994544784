import { IStorageAdapter } from "./storage-adapter";

export class StorageAdapterLocal implements IStorageAdapter {
	public put(key: string, val: string): void {
		if (!this.hasLocalStorage()) {
			console.warn("Local storage is not available on your system");
			// TODO emit event
			return;
		}

		if (key != null) {
			window.localStorage.setItem(key, val);
		}
	}

	public get(key: string): string | null {
		if (!this.hasLocalStorage()) {
			console.warn("Local storage is not available on your system");
			return null;
		}

		if (key == null) {
			return null;
		}

		return window.localStorage.getItem(key);
	}

	public del(key: string): void {
		if (!this.hasLocalStorage()) {
			console.warn("Local storage is not available on your system");
			return;
		}

		if (key != null) {
			window.localStorage.removeItem(key);
		}
	}

	private hasLocalStorage(): boolean {
		let hasLocalStorageSupport = false;
		try {
			hasLocalStorageSupport = window !== undefined && window.localStorage !== null;
			const testKey = "lang.test.local.support.works";
			window.localStorage.setItem(testKey, "foo");
			window.localStorage.removeItem(testKey);
		} catch (e) {
			hasLocalStorageSupport = false;
		}

		return hasLocalStorageSupport;
	}
}
