import * as QueryString from "query-string";

export class URLQuery {
	public static parseURL(url: string): { [index: string]: string } {
		return QueryString.parseUrl(url).query as { [index: string]: string };
	}

	public static findParam(url: string, param: string): string | null {
		let params: { [index: string]: string } = {};
		params = URLQuery.parseURL(url);
		for (const key in params) {
			if (key === param) {
				return params[key];
			}
		}
		return null;
	}
}
