export class Validator {
	public static isNotEmpty(val: string): boolean {
		return val.length !== 0;
	}

	public static isEmail(email: string): boolean {
		const emailTester = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
		if (email.length < 6 || email.length > 254) {
			return false;
		}

		if (!emailTester.test(email)) {
			return false;
		}

		// Local part is 64 characters max
		const parts = email.split("@");
		if (parts[0].length > 64) {
			return false;
		}

		// Domain name / subdomain is limited to 63 characters
		const domainParts = parts[1].split(".");
		if (
			domainParts.some((elem: string, index: number, arr: string[]) => {
				return elem.length > 63;
			})
		) {
			return false;
		}

		return true;
	}

	public static isLengthGreaterThan(input: string, minLength: number): boolean {
		return input.length > minLength;
	}

	public static hasDigitOrSpecialChar(input: string): boolean {
		const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/\s?0123456789]/;
		return regex.test(input);
	}
}
