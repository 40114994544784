/*eslint default-case: 0*/
import * as React from "react";

import { AppContext } from "../../app-context";

import { SignInButtonGoogle } from "./button-google";

import { SignInButtonMicrosoft } from "./button-microsoft";

import { LoginRoutes } from "../";

import { ISignInRequest, ISignInResponse } from "../../api/auth";

import { Validator } from "../../validator";

import {
	ButtonContained,
	ButtonText,
	Colors,
	LoadingIndeterminate,
	Tabs,
	TextField,
	Typo,
} from "@ubik.io/ubik-design-system-react";

import styled from "styled-components";

interface IProps {
	appContext: AppContext;
	onSignInComplete: () => void;
	redirectURI: string;
}

interface IState {
	email: string;
	emailErrorMessage: string;
	emailFocus: boolean;
	password: string;
	passwordErrorMessage: string;
	passwordFocus: boolean;
	requestInProgress: boolean;
	phase: Phase;
	tabList: any[];
}

enum Phase {
	Form,
}

const TabsContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid ${Colors.UI_Grayscale_Light};
`;

const TabsWrapper = styled.div`
	width: 100%;
`;

const MessageContainer = styled.div`
	${Typo.Body}

	text-align: center;
	margin: 32px 0 56px 0;
`;

const SignInPartnersContainer = styled.div`
	max-width: 380px;
	margin: 0 auto;
`;

const SignInPartner = styled.div`
	margin-top: 1em;
`;

const Form = styled.div`
	margin: 0 auto;
	max-width: 380px;
`;

const SignInWrapper = styled.div`
	margin: 0;
`;

const ForgotPassword = styled.div`
	${Typo.Caption}

	color: ${Colors.UI_Primary_Normal};
	text-align: right;

	margin: 1em 0 0 0;
`;

const LoadingContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`;

export class SignInController extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);

		this.onEmailChange = this.onEmailChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);

		this.onKeyPress = this.onKeyPress.bind(this);
		this.onSignInClick = this.onSignInClick.bind(this);

		this.signIn = this.signIn.bind(this);
		this.doSignIn = this.doSignIn.bind(this);

		this.onSignUpClick = this.onSignUpClick.bind(this);

		this.onEmailFocus = this.onEmailFocus.bind(this);
		this.onPasswordFocus = this.onPasswordFocus.bind(this);

		this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);

		this.state = {
			email: "",
			emailErrorMessage: "",
			emailFocus: true,
			password: "",
			passwordErrorMessage: "",
			passwordFocus: false,
			requestInProgress: false,
			phase: Phase.Form,
			tabList: [
				{
					id: "sign-in",
					text: this.props.appContext.i18n.t("components.sign-in.controller.signInTab"),
					active: true,
					enabled: true,
				},
				{
					id: "sign-up",
					text: this.props.appContext.i18n.t("components.sign-in.controller.signUpTab"),
					active: false,
					enabled: true,
					onClick: this.onSignUpClick,
				},
			],
		};
	}

	public onEmailChange(email: string): void {
		this.setState({ email });
	}

	public onPasswordChange(password: string): void {
		this.setState({ password });
	}

	public onEmailFocus(): void {
		this.setState({
			emailFocus: true,
			passwordFocus: false,
		});
	}

	public onPasswordFocus(): void {
		this.setState({
			emailFocus: false,
			passwordFocus: true,
		});
	}

	public onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void {
		if (e.key === "Enter") {
			this.signIn();
		}
	}

	public onSignInClick(): void {
		this.signIn();
	}

	public onSignUpClick(): void {
		this.props.appContext.navigate(LoginRoutes.SignUp);
	}

	private onForgotPasswordClick(): void {
		this.props.appContext.navigate(LoginRoutes.ForgotPassword);
	}

	public render(): JSX.Element {
		switch (this.state.phase) {
			case Phase.Form:
				return this.renderForm();
		}
	}

	public renderForm(): JSX.Element {
		const s = this.props.appContext.layoutSize;
		return (
			<>
				{this.renderLoading()}
				<TabsContainer>
					<TabsWrapper>
						<Tabs size={s} tabList={this.state.tabList} />
					</TabsWrapper>
				</TabsContainer>
				<MessageContainer size={s}>
					{this.props.appContext.i18n.t("components.sign-in.controller.message")}
				</MessageContainer>

				<Form>
					<TextField
						size={s}
						type="email"
						label={this.props.appContext.i18n.t("components.sign-in.controller.emailTextField")}
						focus={this.state.emailFocus}
						onFocus={this.onEmailFocus}
						onValueChange={this.onEmailChange}
						onKeyPress={this.onKeyPress}
						errorMessage={this.state.emailErrorMessage}
						value={this.state.email}
						disabled={this.state.requestInProgress}
					/>
					<TextField
						size={s}
						type="password"
						label={this.props.appContext.i18n.t("components.sign-in.controller.passwordTextField")}
						focus={this.state.passwordFocus}
						onFocus={this.onPasswordFocus}
						onValueChange={this.onPasswordChange}
						onKeyPress={this.onKeyPress}
						errorMessage={this.state.passwordErrorMessage}
						value={this.state.password}
						disabled={this.state.requestInProgress}
					/>

					<SignInWrapper>
						<ButtonContained
							size={s}
							text={this.props.appContext.i18n.t("components.sign-in.controller.signInButton")}
							onClick={this.onSignInClick}
							disabled={this.state.requestInProgress}
						/>
					</SignInWrapper>

					<ForgotPassword size={s}>
						<ButtonText
							size={s}
							text={this.props.appContext.i18n.t("components.sign-in.controller.forgotPasswordButton")}
							onClick={this.onForgotPasswordClick}
							disabled={this.state.requestInProgress}
						/>
					</ForgotPassword>
				</Form>

				<SignInPartnersContainer>
					<SignInPartner>
						<SignInButtonGoogle
							appContext={this.props.appContext}
							disabled={this.state.requestInProgress}
							redirectURI={this.props.redirectURI}
						/>
					</SignInPartner>
					<SignInPartner>
						<SignInButtonMicrosoft
							appContext={this.props.appContext}
							disabled={this.state.requestInProgress}
							redirectURI={this.props.redirectURI}
						/>
					</SignInPartner>
				</SignInPartnersContainer>
			</>
		);
	}

	private renderLoading(): React.ReactNode {
		if (!this.state.requestInProgress) {
			return null;
		}
		return (
			<LoadingContainer>
				<LoadingIndeterminate />
			</LoadingContainer>
		);
	}
	private signIn() {
		let valid = true;

		let emailMsg = "";
		let passwordMsg = "";
		if (!Validator.isNotEmpty(this.state.email)) {
			valid = false;
			emailMsg = this.props.appContext.i18n.t("components.sign-in.controller.validation.emailCannotBeEmpty");
		}
		if (!Validator.isNotEmpty(this.state.password)) {
			valid = false;
			passwordMsg = this.props.appContext.i18n.t("components.sign-in.controller.validation.passwordCannotBeEmpty");
		}

		// We use those to reduce server side processing
		if (valid && !Validator.isEmail(this.state.email)) {
			valid = false;
			emailMsg = this.props.appContext.i18n.t("components.sign-in.controller.validation.invalidEmailOrPassword");
		}
		if (valid && !Validator.isLengthGreaterThan(this.state.password, 5)) {
			valid = false;
			emailMsg = this.props.appContext.i18n.t("components.sign-in.controller.validation.invalidEmailOrPassword");
		}
		if (valid && !Validator.hasDigitOrSpecialChar(this.state.password)) {
			valid = false;
			emailMsg = this.props.appContext.i18n.t("components.sign-in.controller.validation.invalidEmailOrPassword");
		}

		this.setState({
			emailErrorMessage: emailMsg,
			passwordErrorMessage: passwordMsg,
			requestInProgress: valid,
		});

		if (valid) {
			this.doSignIn();
		}
	}

	private doSignIn() {
		const req: ISignInRequest = {
			method: "BASIC_AUTH",
			data: {
				email: this.state.email,
				password: this.state.password,
			},
		};

		this.props.appContext.api.auth
			.signIn(req)
			.then((resp: ISignInResponse) => {
				this.props.onSignInComplete();
			})
			.catch((code: number) => {
				switch (code) {
					case 403: {
						this.setState({
							emailErrorMessage: this.props.appContext.i18n.t(
								"components.sign-in.controller.validation.invalidEmailOrPassword"
							),
							requestInProgress: false,
						});
						break;
					}
					default: {
						const snackbarRef = this.props.appContext.snackbarRef;
						if (snackbarRef.current !== null) {
							snackbarRef.current?.show({
								actionMessage: this.props.appContext.i18n.t("components.sign-in.controller.snackbar.tryAgainAction"),
								message: this.props.appContext.i18n.t("components.sign-in.controller.snackbar.defaultError"),
								onActionClick: this.signIn,
							});
						}

						this.setState({
							requestInProgress: false,
						});
						break;
					}
				}
			});
	}
}
