import * as React from "react";

import { I18n, IProviderI18nStub } from "../i18n";

import { IStorageAdapter, StorageAdapterStub } from "../storage";

import { AppContextAPI } from "./api";

import { Config } from "./config";

import { LayoutSize, Snackbar } from "@ubik.io/ubik-design-system-react";

import { NavigateFunction, Location } from "react-router-dom";

import { IWindowLocationProvider } from "../window-location-provider";

import { WindowLocationProviderStub } from "../window-location-provider-stub";

export class AppContext {
	public location: Location;
	public navigate: NavigateFunction;
	public i18n: I18n;
	public api: AppContextAPI;
	public storage: IStorageAdapter;
	public config: Config;
	public rootURL: string;
	public layoutSize: LayoutSize;
	public snackbarRef: React.RefObject<Snackbar>;
	public windowLocation: IWindowLocationProvider;

	public constructor() {
		this.api = new AppContextAPI();
		this.config = new Config();

		this.location = {
			state: "",
			key: "",
			pathname: "",
			search: "",
			hash: "",
		};

		this.navigate = (to: any) => {
			throw new Error("Not initialized");
		};

		this.i18n = new I18n(new IProviderI18nStub(), "en", ["en"]);
		this.storage = new StorageAdapterStub();

		this.layoutSize = LayoutSize.Large;

		this.rootURL = "/login";

		this.snackbarRef = React.createRef<Snackbar>();

		this.windowLocation = new WindowLocationProviderStub();
	}
}
