/*eslint default-case: 0*/

import * as React from "react";

import { AppContext } from "../../app-context";

import { IReCAPTCHAProvider } from "../../recaptcha";

import { Transition, TransitionStyleFade } from "@ubik.io/ubik-design-system-react";

import { ForgotPasswordForm } from "./form";

import { ForgotPasswordThankYou } from "./thank-you";

interface IProps {
	appContext: AppContext;
	reCAPTCHA: IReCAPTCHAProvider;
	redirectURI: string;
}

interface IState {
	email: string;
	phase: Phase;
}

enum Phase {
	Form,
	ThankYou,
}

export class ForgotPasswordController extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			email: "",
			phase: Phase.Form,
		};

		this.onTryAgainClick = this.onTryAgainClick.bind(this);
		this.onForgotPasswordComplete = this.onForgotPasswordComplete.bind(this);
	}

	public onTryAgainClick(): void {
		this.setState({
			phase: Phase.Form,
		});
	}

	public onForgotPasswordComplete(email: string): void {
		this.setState({
			email,
			phase: Phase.ThankYou,
		});
	}

	public render(): JSX.Element {
		const s = this.props.appContext.layoutSize;
		const transitionKey = Transition.encodeKey(this.state.phase.toString(), s);

		return (
			<Transition transitionStyle={TransitionStyleFade}>
				<div key={transitionKey}>{this.renderPhase()}</div>
			</Transition>
		);
	}

	private renderPhase(): JSX.Element {
		switch (this.state.phase) {
			case Phase.ThankYou:
				return (
					<ForgotPasswordThankYou
						appContext={this.props.appContext}
						email={this.state.email}
						onTryAgainClick={this.onTryAgainClick}
					/>
				);

			case Phase.Form:
				return (
					<ForgotPasswordForm
						appContext={this.props.appContext}
						onForgotPasswordComplete={this.onForgotPasswordComplete}
						reCAPTCHA={this.props.reCAPTCHA}
						email={this.state.email}
						redirectURI={this.props.redirectURI}
					/>
				);
		}
	}
}
