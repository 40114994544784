import { I18nHelper, IProviderI18n } from ".";

import { IStorageAdapter } from "../storage";

export class I18n {
	private provider: IProviderI18n;
	private currentLanguage: string;
	private availableLanguages: string[];

	public constructor(provider: IProviderI18n, currentLanguage: string, availableLanguages: string[]) {
		this.provider = provider;
		this.currentLanguage = currentLanguage;
		this.availableLanguages = availableLanguages;
	}

	// You should always relead
	public setLanguage(storageAdapter: IStorageAdapter, lang: string): void {
		let langFound = false;

		for (const availableLang of this.availableLanguages) {
			if (availableLang === lang) {
				langFound = true;
				break;
			}
		}

		if (!langFound) {
			console.error("Impossible to set language to '", lang, "' because it is not supported.");
			return;
		}

		const sanitizedLang = I18nHelper.sanitizeLang(lang);
		this.currentLanguage = sanitizedLang;

		I18nHelper.saveLanguage(storageAdapter, lang);
	}

	public getCurrentLanguage(): string {
		return this.currentLanguage;
	}

	public getAvailableLangauges(): string[] {
		return this.availableLanguages;
	}

	public t(key: string, ...args: any[]): string {
		if (args.length === 0) {
			return this.provider.t(key);
		}
		return this.provider.t(key, args);
	}
}
