export class LoginRoutes {
	public static Root = "/login";

	public static SignUp = LoginRoutes.Root + "/sign-up";
	public static SignIn = LoginRoutes.Root + "/sign-in";
	public static SignUpConfirm = LoginRoutes.Root + "/sign-up-confirm";
	public static ForgotPassword = LoginRoutes.Root + "/forgot-password";
	public static SignInOAuthCallback = LoginRoutes.Root + "/sign-in-oauth-callback";
	public static ForgotPasswordCallback = LoginRoutes.Root + "/forgot-password-callback";

	public static toRelativePath(prefix: string, route: string): string {
		if (route.substring(0, prefix.length) !== prefix) {
			return route;
		}

		return route.substring(prefix.length + 1);
	}
}
