import * as React from "react";

import { AppContext } from "../../app-context";

import { ButtonText, Colors, Typo } from "@ubik.io/ubik-design-system-react";

import styled from "styled-components";

interface IProps {
	appContext: AppContext;
	email: string;
	onTryAgainClick: () => void;
}

const Container = styled.div`
	${Typo.Body}
	text-align: center;
`;
const MessageContainer = styled.div`
	margin: 32px 0;
`;
const ThankYouContainer = styled.div`
	margin-bottom: 32px;
`;

export class SignUpFormThankYou extends React.Component<IProps, any> {
	public render(): JSX.Element {
		const s = this.props.appContext.layoutSize;

		const tyEmail = "<span style='color: " + Colors.UI_Primary_Normal + ";'>" + this.props.email + "</span>";

		const ty = {
			__html: "<span>" + this.props.appContext.i18n.t("components.sign-up.thankyou.message", tyEmail) + "</span>",
		};

		return (
			<Container size={s}>
				<MessageContainer>{this.props.appContext.i18n.t("components.sign-up.thankyou.success")}</MessageContainer>
				<ThankYouContainer dangerouslySetInnerHTML={ty} />
				<ButtonText
					size={s}
					text={this.props.appContext.i18n.t("components.sign-up.thankyou.tryAgain")}
					onClick={this.props.onTryAgainClick}
				/>
			</Container>
		);
	}
}
