/*eslint default-case: 0*/

import * as React from "react";

import { AppContext } from "../../app-context";

import { SignInOAuthChecking } from "./checking";

import { ISignInRequest, ISignInResponse } from "../../api/auth";
import { OAuth } from "../../oauth";

import { URLQuery } from "../../url";

import { IOAuthState, SignInOAuthHelper } from "./helper";
import { LoginRoutes } from "../routes";

interface IProps {
	appContext: AppContext;
	onSignInComplete: () => void;
}

interface IState {
	oAuthState: IOAuthState | null;
	phase: Phase;
}

enum Phase {
	Checking,
}

export class SignInOAuthController extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);

		const stateParams = URLQuery.findParam(this.props.appContext.location.search, "state");

		this.state = {
			oAuthState: SignInOAuthHelper.decodeOAuthState(stateParams),
			phase: Phase.Checking,
		};
	}

	public componentDidMount(): void {
		this.checkOAuthResult();
	}

	public render(): JSX.Element {
		return <>{this.renderPhase()}</>;
	}

	public renderPhase(): JSX.Element {
		switch (this.state.phase) {
			case Phase.Checking:
				return <SignInOAuthChecking appContext={this.props.appContext} />;
		}
	}

	private checkOAuthResult() {
		const code = URLQuery.findParam(this.props.appContext.location.search, "code");
		if (code === null) {
			this.oAuthInternalError();
			return;
		}

		if (this.state.oAuthState === null) {
			this.oAuthInternalError();
			return;
		}
		const state = this.state.oAuthState as IOAuthState;

		let req: ISignInRequest = {
			method: "OAUTH2_GOOGLE",
			data: {
				code: "",
				redirect_uri: "",
				scope: "",
			},
		};
		switch (state.oauth_provider) {
			case "google":
				req = {
					method: "OAUTH2_GOOGLE",
					data: {
						code: code,
						redirect_uri: this.props.appContext.rootURL,
						scope: OAuth.googleGetScope(),
					},
				};
				break;
			case "microsoft":
				req = {
					method: "OAUTH2_MICROSOFT",
					data: {
						code: code,
						redirect_uri: this.props.appContext.rootURL,
						scope: OAuth.microsoftGetScope(),
					},
				};
				break;
		}

		this.props.appContext.api.auth
			.signIn(req)
			.then((resp: ISignInResponse) => {
				this.props.onSignInComplete();
			})
			.catch((code: number) => {
				switch (code) {
					case 403: {
						this.oAuthNotFound();
						break;
					}
					case 412: {
						this.oAuthNotFound();
						break;
					}
					default: {
						this.oAuthInternalError();
						break;
					}
				}
			});
	}

	public oAuthNotFound(): void {
		const snackbarRef = this.props.appContext.snackbarRef;

		if (snackbarRef.current !== null) {
			snackbarRef.current?.show({
				message: this.props.appContext.i18n.t("components.sign-in-oauth.controller.validation.emailNotFound"),
			});
		}

		this.props.appContext.navigate(LoginRoutes.SignIn);
	}

	public oAuthInternalError(): void {
		const snackbarRef = this.props.appContext.snackbarRef;

		if (snackbarRef.current !== null) {
			snackbarRef.current?.show({
				message: this.props.appContext.i18n.t("components.sign-in-oauth.controller.validation.defaultError"),
			});
		}

		this.props.appContext.navigate(LoginRoutes.SignIn);
	}
}
