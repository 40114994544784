import * as React from "react";

import { AppContext } from "../../app-context";

import {
	ButtonContained,
	Colors,
	LoadingIndeterminate,
	Tabs,
	TextField,
	Typo,
} from "@ubik.io/ubik-design-system-react";

import { Validator } from "../../validator";

import { LoginRoutes } from "../";

import { IReCAPTCHAProvider } from "../../recaptcha";

import styled from "styled-components";

interface IProps {
	appContext: AppContext;
	fullname: string;
	email: string;
	onSignUpComplete: (fullname: string, email: string) => void;
	onUserAlreadyExists: (email: string) => void;
	reCAPTCHA: IReCAPTCHAProvider;
	redirectURI: string;
}

interface IState {
	email: string;
	emailErrorMessage: string;
	emailFocus: boolean;
	fullname: string;
	fullnameErrorMessage: string;
	fullnameFocus: boolean;
	requestInProgress: boolean;
	phase: Phase;
	tabList: any[];
}

enum Phase {
	Form,
}

const TabsContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: center;
	border-bottom: 1px solid ${Colors.UI_Grayscale_Light};
`;

const TabsWrapper = styled.div`
	width: 100%;
`;

const MessageContainer = styled.div`
	${Typo.Body}

	text-align: center;
	margin: 32px auto 56px auto;
	max-width: 380px;
`;

const FormContainer = styled.div`
	margin: 0 auto 28px auto;
	max-width: 380px;
`;

const SubmitButtonContainer = styled.div`
	max-width: 380px;
	margin: 0 auto;
`;

const LoadingContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`;

export class SignUpForm extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);

		this.onEmailChange = this.onEmailChange.bind(this);
		this.onFullnameChange = this.onFullnameChange.bind(this);
		this.onSignUpClick = this.onSignUpClick.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.handleError = this.handleError.bind(this);
		this.onSignInClick = this.onSignInClick.bind(this);
		this.onEmailFocus = this.onEmailFocus.bind(this);
		this.onFullnameFocus = this.onFullnameFocus.bind(this);

		this.state = {
			fullname: this.props.fullname,
			fullnameErrorMessage: "",
			fullnameFocus: true,
			email: this.props.email,
			emailErrorMessage: "",
			emailFocus: false,
			requestInProgress: false,
			phase: Phase.Form,
			tabList: [
				{
					id: "sign-in",
					text: this.props.appContext.i18n.t("components.sign-up.controller.signInTab"),
					active: false,
					enabled: true,
					onClick: this.onSignInClick,
				},
				{
					id: "sign-up",
					text: this.props.appContext.i18n.t("components.sign-up.controller.signUpTab"),
					active: true,
					enabled: true,
				},
			],
		};
	}

	public onEmailChange(email: string): void {
		this.setState({ email });
	}

	public onEmailFocus(): void {
		this.setState({
			emailFocus: true,
			fullnameFocus: false,
		});
	}

	public onFullnameChange(fullname: string): void {
		this.setState({ fullname });
	}

	public onFullnameFocus(): void {
		this.setState({
			emailFocus: false,
			fullnameFocus: true,
		});
	}

	public onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void {
		if (e.key === "Enter") {
			this.onSignUpClick();
		}
	}

	public onSignInClick(): void {
		this.props.appContext.navigate(LoginRoutes.SignIn);
	}

	public onSignUpClick(): void {
		let valid = true;

		let fullNameMsg = "";
		if (!Validator.isNotEmpty(this.state.fullname)) {
			valid = false;
			fullNameMsg = this.props.appContext.i18n.t("components.sign-up.form.validation.nameCannotBeEmpty");
		}

		let emailMsg = "";
		if (!Validator.isEmail(this.state.email)) {
			valid = false;
			emailMsg = this.props.appContext.i18n.t("components.sign-up.form.validation.invalidEmail");
		}

		this.setState({
			emailErrorMessage: emailMsg,
			fullnameErrorMessage: fullNameMsg,
			requestInProgress: valid,
		});

		if (valid) {
			this.doSignUp();
		}
	}

	public render(): JSX.Element {
		switch (this.state.phase) {
			case Phase.Form:
				return this.renderForm();
		}
	}

	public renderForm(): JSX.Element {
		const s = this.props.appContext.layoutSize;

		return (
			<>
				{this.renderLoading()}
				<TabsContainer>
					<TabsWrapper>
						<Tabs size={s} tabList={this.state.tabList} />
					</TabsWrapper>
				</TabsContainer>

				<MessageContainer size={s}>{this.props.appContext.i18n.t("components.sign-up.form.message")}</MessageContainer>

				<FormContainer>
					<TextField
						size={s}
						type="text"
						label={this.props.appContext.i18n.t("components.sign-up.form.yourName")}
						focus={this.state.fullnameFocus}
						onFocus={this.onFullnameFocus}
						onValueChange={this.onFullnameChange}
						errorMessage={this.state.fullnameErrorMessage}
						onKeyPress={this.onKeyPress}
						value={this.state.fullname}
						disabled={this.state.requestInProgress}
					/>
					<TextField
						size={s}
						type="email"
						label={this.props.appContext.i18n.t("components.sign-up.form.emailAddress")}
						focus={this.state.emailFocus}
						onFocus={this.onEmailFocus}
						onValueChange={this.onEmailChange}
						errorMessage={this.state.emailErrorMessage}
						onKeyPress={this.onKeyPress}
						value={this.state.email}
						disabled={this.state.requestInProgress}
					/>
				</FormContainer>

				<SubmitButtonContainer>
					<ButtonContained
						size={s}
						text={this.props.appContext.i18n.t("components.sign-up.form.signUp")}
						onClick={this.onSignUpClick}
						disabled={this.state.requestInProgress}
					/>
				</SubmitButtonContainer>
			</>
		);
	}

	private renderLoading() {
		if (!this.state.requestInProgress) {
			return null;
		}
		return (
			<LoadingContainer>
				<LoadingIndeterminate />
			</LoadingContainer>
		);
	}

	private doSignUp() {
		this.props.reCAPTCHA
			.getReCAPTCHAValue()
			.then((recaptchaValue: string) => {
				const confirmURL = this.buildConfirmURL();

				this.props.appContext.api.auth
					.signUp({
						confirm_url: confirmURL,
						email: this.state.email,
						full_name: this.state.fullname,
						language: this.props.appContext.i18n.getCurrentLanguage(),
						recaptcha_response: recaptchaValue,
					})
					.then((value: void) => {
						this.setState({
							requestInProgress: false,
						});

						this.props.onSignUpComplete(this.state.fullname, this.state.email);
					})
					.catch(this.handleError);
			})
			.catch(this.handleError);
	}

	private handleError(error: any) {
		this.setState({ requestInProgress: false });

		switch (error) {
			case 409: {
				this.props.onUserAlreadyExists(this.state.email);
				break;
			}
			default: {
				const snackbarRef = this.props.appContext.snackbarRef;
				if (snackbarRef.current !== null) {
					snackbarRef.current?.show({
						actionMessage: this.props.appContext.i18n.t("components.sign-up.form.validation.tryAgain"),
						message: this.props.appContext.i18n.t("components.sign-up.form.validation.defaultError"),
						onActionClick: this.onSignUpClick,
					});
				}
				break;
			}
		}
	}

	private buildConfirmURL(): string {
		let url = this.props.appContext.rootURL + LoginRoutes.SignUpConfirm;
		if (this.props.redirectURI !== "") {
			url += "?redirect_uri=" + this.props.redirectURI;
		}

		return url;
	}
}
