import * as React from "react";

import { AppContext } from "../app-context";

import { Colors, LayoutSize, LogoLightSloganEn, LogoLightSloganFr, Typo } from "@ubik.io/ubik-design-system-react";

import styled from "styled-components";

interface IProps {
	appContext: AppContext;
	onLanguageChange: (lang: string) => void;
}

const Container = styled.header`
	display: flex;
	justify-content: space-between;
	height: 64px;
	padding: 8px;
`;

const LangSelector = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-end;
`;

interface ILangElemProps {
	size: LayoutSize;
	active: boolean;
}
const LangElem = styled.div<ILangElemProps>`
	${Typo.Subheading}
	text-transform: uppercase;
	color: ${Colors.UI_Grayscale_Lightest};

	padding: 0 1em 0 0.5em;
	margin-top: 0.5em;

	cursor: ${(props: ILangElemProps) => {
		if (props.active) {
			return "normal";
		}
		return "pointer";
	}};

	background-color: ${(props: ILangElemProps) => {
		if (props.active) {
			return Colors.UI_Primary_Normal;
		}
		return "transparent";
	}};

	&:hover {
		background-color: ${Colors.UI_Primary_Normal};
	}
`;

export class Header extends React.Component<IProps, any> {
	public constructor(props: IProps) {
		super(props);

		this.setLanguageFr = this.setLanguageFr.bind(this);
		this.setLanguageEn = this.setLanguageEn.bind(this);
	}

	public setLanguageFr(): void {
		if (this.props.appContext.i18n.getCurrentLanguage() === "fr") {
			return;
		}
		this.props.onLanguageChange("fr");
	}

	public setLanguageEn(): void {
		if (this.props.appContext.i18n.getCurrentLanguage() === "en") {
			return;
		}
		this.props.onLanguageChange("en");
	}

	public render(): JSX.Element {
		let frActive = false;
		let enActive = false;
		switch (this.props.appContext.i18n.getCurrentLanguage()) {
			case "fr":
				frActive = true;
				break;
			default:
				enActive = true;
				break;
		}

		let logo = <LogoLightSloganFr />;
		if (this.props.appContext.i18n.getCurrentLanguage() == "en") {
			logo = <LogoLightSloganEn />;
		}

		const s = this.props.appContext.layoutSize;

		return (
			<Container>
				{logo}

				<LangSelector>
					<LangElem size={s} active={frActive} onClick={this.setLanguageFr} id="header-lang-elem-fr">
						{this.props.appContext.i18n.t("components.header.lang.french")}
					</LangElem>
					<LangElem size={s} active={enActive} onClick={this.setLanguageEn} id="header-lang-elem-en">
						{this.props.appContext.i18n.t("components.header.lang.english")}
					</LangElem>
				</LangSelector>
			</Container>
		);
	}
}
