import * as React from "react";

interface IProps {
	onloadCallback: () => void;
	siteKey: string;
	verifyCallback: (value: string) => void;
}

enum Phase {
	LOADING,
	LOADED,
}

interface IState {
	phase: Phase;
}

export class ReCAPTCHA extends React.Component<IProps, IState> {
	private readyCheckInterval: ReturnType<typeof setInterval>;

	public constructor(props: IProps) {
		super(props);

		this.state = { phase: Phase.LOADING };

		this.checkReadyState = this.checkReadyState.bind(this);

		this.readyCheckInterval = setInterval(this.checkReadyState, 333);
	}

	public checkReadyState(): void {
		if (!this.doCheckReadyState() || this.state.phase === Phase.LOADED) {
			return;
		}

		clearInterval(this.readyCheckInterval);

		(window as any).grecaptcha.render("recaptcha", {
			badge: "bottomright",
			callback: this.props.verifyCallback,
			sitekey: this.props.siteKey,
			size: "invisible",
		});
		this.props.onloadCallback();
	}

	public execute(): void {
		(window as any).grecaptcha.execute();
	}

	public reset(): void {
		(window as any).grecaptcha.reset();
	}

	public render(): JSX.Element {
		return <div id="recaptcha" />;
	}

	private doCheckReadyState(): boolean {
		return (
			typeof window !== "undefined" &&
			typeof (window as any).grecaptcha !== "undefined" &&
			typeof (window as any).grecaptcha.render === "function"
		);
	}
}
