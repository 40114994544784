import { I18n } from "./i18n";

import { I18nHelper } from "./helper";
import { IInitializerI18n } from "./initializer";
import { IProviderI18nDefault } from "./provider-default";

import { IStorageAdapter } from "../storage";

export class I18nextInitializer implements IInitializerI18n {
	public init(storageAdapter: IStorageAdapter): Promise<I18n> {
		return new Promise((onResolve, onReject) => {
			const currentLanguage = I18nHelper.getBestLanguage(storageAdapter);

			let pack: { [index: string]: string } = {};
			pack = require("./languagepack/" + currentLanguage + "/translation.json");

			const translationMap = new Map<string, string>();

			Object.keys(pack).forEach((key) => {
				const processedKeys = this.processPackKey(pack[key], key, "");

				processedKeys.forEach((value: string, processedKey: string) => {
					translationMap.set(processedKey, value);
				});
			});

			const prov = new IProviderI18nDefault(translationMap);

			onResolve(new I18n(prov, currentLanguage, ["en", "fr"]));
		});
	}

	private processPackKey(key: any, keyName: string, prefix: string): Map<string, string> {
		const result = new Map<string, string>();

		if (typeof key === "string") {
			prefix = prefix === "" ? keyName : prefix + "." + keyName;
			result.set(prefix, key);
			return result;
		}

		if (typeof key !== "object") {
			console.warn("Invalid key found in i18h languagepack: ", prefix, key);
			return result;
		}

		prefix = prefix === "" ? keyName : prefix + "." + keyName;
		Object.keys(key).forEach((newKey) => {
			const childrenKeys = this.processPackKey(key[newKey], newKey, prefix);
			childrenKeys.forEach((value: string, key: string) => {
				result.set(key, value);
			});
		});

		return result;
	}
}
