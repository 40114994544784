export class I18nBrowserHelper {
	public static getNavigatorLang(): string | null {
		if (!window.navigator) {
			return null;
		}

		if (window.navigator.language) {
			return window.navigator.language;
		}

		if (window.navigator.languages && window.navigator.languages.length > 0) {
			return window.navigator.languages[0];
		}

		return null;
	}
}
