/*eslint default-case: 0*/
import * as React from "react";

import { AppContext } from "../../app-context";

import { Transition, TransitionStyleFade } from "@ubik.io/ubik-design-system-react";

import { ForgotPasswordCallbackForm } from "./form";

import { IReCAPTCHAProvider } from "../../recaptcha";

import { LoginRoutes } from "../routes";

enum Phase {
	Form,
}

interface IProps {
	appContext: AppContext;
	reCAPTCHA: IReCAPTCHAProvider;
	onSignInComplete: () => void;
}

interface IState {
	phase: Phase;
}

export class ForgotPasswordCallbackController extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			phase: Phase.Form,
		};

		this.onExpired = this.onExpired.bind(this);
		this.onSignInError = this.onSignInError.bind(this);
	}

	public render(): JSX.Element {
		const s = this.props.appContext.layoutSize;
		const transitionKey = Transition.encodeKey(this.state.phase.toString(), s);

		return (
			<Transition transitionStyle={TransitionStyleFade}>
				<div key={transitionKey}>{this.renderPhase()}</div>
			</Transition>
		);
	}

	public onExpired(): void {
		const snackbarRef = this.props.appContext.snackbarRef;
		if (snackbarRef.current !== null) {
			const onActionClick = (): void => {
				this.props.appContext.navigate(LoginRoutes.ForgotPassword);
			};

			snackbarRef.current.show({
				message: this.props.appContext.i18n.t("components.forget-password-callback.controller.snackbar.expiredMessage"),
				actionMessage: this.props.appContext.i18n.t(
					"components.forget-password-callback.controller..snackbar.expiredAction"
				),
				onActionClick,
			});
		}

		this.props.appContext.navigate(LoginRoutes.SignIn);
	}

	public onSignInError(): void {
		const snackbarRef = this.props.appContext.snackbarRef;

		// We don't want the standard error handler here since the password reset request was
		// already processed and doing it twice will result in an error.
		// We will just send back to the login screen and invite user to try again
		if (snackbarRef.current !== null) {
			snackbarRef.current?.show({
				message: this.props.appContext.i18n.t("components.forget-password-callback.controller.snackbar.signInFailed"),
			});
		}

		this.props.appContext.navigate(LoginRoutes.SignIn);
	}

	public renderPhase(): JSX.Element {
		switch (this.state.phase) {
			case Phase.Form:
				return (
					<ForgotPasswordCallbackForm
						appContext={this.props.appContext}
						onExpired={this.onExpired}
						onSignInError={this.onSignInError}
						reCAPTCHA={this.props.reCAPTCHA}
						onSignInComplete={this.props.onSignInComplete}
					/>
				);
		}
	}
}
