import * as React from "react";

import { AppContext } from "../../app-context";

import { ButtonContained, LoadingIndeterminate, TextField, Typo } from "@ubik.io/ubik-design-system-react";

import { Validator } from "../../validator";

import { URLQuery } from "../../url";

import { IReCAPTCHAProvider } from "../../recaptcha";

import { ISignInRequest } from "../../api/auth";

import styled from "styled-components";

interface IProps {
	appContext: AppContext;
	reCAPTCHA: IReCAPTCHAProvider;
	onExpired: () => void;
	// This is used when a user finishes its password reset, but somthing goes wrong with the sign in process
	onSignInError: () => void;
	onSignInComplete: () => void;
}

interface IState {
	password: string;
	passwordErrorMessage: string;
	passwordFocus: boolean;
	confirmPassword: string;
	confirmPasswordErrorMessage: string;
	confirmPasswordFocus: boolean;
	requestInProgress: boolean;
}

const MessageContainer = styled.div`
	${Typo.Body}

	text-align: center;
	margin: 32px auto 56px auto;
	max-width: 380px;
`;

const FormContainer = styled.div`
	margin: 0 auto 28px auto;
	max-width: 380px;
`;

const SubmitButtonContainer = styled.div`
	max-width: 380px;
	margin: 0 auto;
`;

const LoadingContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
`;

export class ForgotPasswordCallbackForm extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);
		this.state = {
			confirmPassword: "",
			confirmPasswordErrorMessage: "",
			confirmPasswordFocus: false,
			password: "",
			passwordErrorMessage: "",
			passwordFocus: true,
			requestInProgress: false,
		};

		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);
		this.onConfirmClick = this.onConfirmClick.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.handleError = this.handleError.bind(this);
		this.onPasswordFocus = this.onPasswordFocus.bind(this);
		this.onConfirmPasswordFocus = this.onConfirmPasswordFocus.bind(this);
	}

	public onPasswordChange(password: string): void {
		this.setState({ password });
	}

	public onConfirmPasswordChange(confirmPassword: string): void {
		this.setState({ confirmPassword });
	}

	public onConfirmClick(): void {
		let valid = true;

		let passwordMsg = "";
		if (!Validator.isLengthGreaterThan(this.state.password, 5)) {
			valid = false;
			passwordMsg = this.props.appContext.i18n.t(
				"components.forget-password-callback.form.validation.passwordAtLeast6Char"
			);
		}
		if (valid && !Validator.hasDigitOrSpecialChar(this.state.password)) {
			valid = false;
			passwordMsg = this.props.appContext.i18n.t(
				"components.forget-password-callback.form.validation.passwordAtLeast1DigitOrSpecialChar"
			);
		}

		let confirmMsg = "";
		if (this.state.password !== this.state.confirmPassword) {
			valid = false;
			confirmMsg = this.props.appContext.i18n.t(
				"components.forget-password-callback.form.validation.passwordsMismatch"
			);
		}

		this.setState({
			confirmPasswordErrorMessage: confirmMsg,
			passwordErrorMessage: passwordMsg,
			requestInProgress: valid,
			passwordFocus: true,
			confirmPasswordFocus: false,
		});

		if (valid) {
			this.doConfirm();
		}
	}

	public onKeyPress(e: React.KeyboardEvent<HTMLInputElement>): void {
		if (e.key === "Enter") {
			this.onConfirmClick();
		}
	}

	public onPasswordFocus() {
		this.setState({
			passwordFocus: true,
			confirmPasswordFocus: false,
		});
	}

	public onConfirmPasswordFocus() {
		this.setState({
			passwordFocus: false,
			confirmPasswordFocus: true,
		});
	}

	public render(): JSX.Element {
		const s = this.props.appContext.layoutSize;
		return (
			<>
				{this.renderLoading()}
				<MessageContainer size={s}>
					{this.props.appContext.i18n.t("components.forget-password-callback.form.message")}
				</MessageContainer>

				<FormContainer>
					<TextField
						size={s}
						type="password"
						label={this.props.appContext.i18n.t("components.forget-password-callback.form.passwordField")}
						focus={this.state.passwordFocus}
						onFocus={this.onPasswordFocus}
						onValueChange={this.onPasswordChange}
						errorMessage={this.state.passwordErrorMessage}
						onKeyPress={this.onKeyPress}
						key="password"
						value={this.state.password}
						disabled={this.state.requestInProgress}
					/>
					<TextField
						size={s}
						type="password"
						label={this.props.appContext.i18n.t("components.forget-password-callback.form.confirmPasswordField")}
						focus={this.state.confirmPasswordFocus}
						onFocus={this.onConfirmPasswordFocus}
						onValueChange={this.onConfirmPasswordChange}
						errorMessage={this.state.confirmPasswordErrorMessage}
						onKeyPress={this.onKeyPress}
						key="password-confirm"
						value={this.state.confirmPassword}
						disabled={this.state.requestInProgress}
					/>
				</FormContainer>

				<SubmitButtonContainer>
					<ButtonContained
						size={s}
						text={this.props.appContext.i18n.t("components.forget-password-callback.form.confirmButton")}
						onClick={this.onConfirmClick}
						disabled={this.state.requestInProgress}
					/>
				</SubmitButtonContainer>
			</>
		);
	}

	private renderLoading() {
		if (!this.state.requestInProgress) {
			return null;
		}
		return (
			<LoadingContainer>
				<LoadingIndeterminate />
			</LoadingContainer>
		);
	}

	private doConfirm(): void {
		const id = URLQuery.findParam(this.props.appContext.location.search, "id");
		const code = URLQuery.findParam(this.props.appContext.location.search, "code");

		if (id === null || id === "" || code === "" || code === null) {
			this.handleError("Cannot find code or id in url");
			return;
		}

		this.props.reCAPTCHA
			.getReCAPTCHAValue()
			.then((recaptchaValue: string) => {
				this.props.appContext.api.auth
					.confirmUser({
						code,
						id,
						new_password: this.state.password,
						recaptcha_response: recaptchaValue,
					})
					.then((email: string) => {
						const loginReq: ISignInRequest = {
							data: {
								email,
								password: this.state.password,
							},
							method: "BASIC_AUTH",
						};

						const am = this.props.appContext.api.auth;
						am.signIn(loginReq)
							.then(() => {
								this.props.onSignInComplete();
							})
							.catch(() => {
								this.props.onSignInError();
							});
					})
					.catch(this.handleError);
			})
			.catch(this.handleError);
	}

	private handleError(error: any): void {
		this.setState({ requestInProgress: false });

		switch (error) {
			case 403: {
				this.props.onExpired();
				break;
			}
			default: {
				const snackbarRef = this.props.appContext.snackbarRef;

				if (snackbarRef.current !== null) {
					snackbarRef.current?.show({
						actionMessage: this.props.appContext.i18n.t(
							"components.forget-password-callback.form.snackbar.tryAgainAction"
						),
						message: this.props.appContext.i18n.t("components.forget-password-callback.form.snackbar.defaultError"),
						onActionClick: this.onConfirmClick,
					});
				}
				break;
			}
		}
	}
}
